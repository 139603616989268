import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import BlogHeader from "../../components/Blog/BlogHeader"
import BlogPost from "../../components/BlogPost/BlogPost"
import Footer from "../../components/Footer/Footer"
import Layout from "../../components/Layout/Layout"
import NavbarContainer from "../../components/misc/NavbarContainer/NavbarContainer"
import useBlogList from "../../hooks/useBlogList"

function BlogPage() {
  const blogList = useBlogList()
  const data = useStaticQuery(graphql`{
  pho: file(relativePath: {eq: "blog3_pho.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  rolls: file(relativePath: {eq: "blog3_rolls.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  noodle: file(relativePath: {eq: "blog3_noodle.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  banhMi: file(relativePath: {eq: "blog3_banhMi.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  seaFood: file(relativePath: {eq: "blog3_seaFood.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
}
`)

  const { _, ...photos } = data
  const { title, time, article, postId } = blogList.find(
    blog => blog.postId === "top-vietnamese"
  )
  return (
    <ParallaxProvider>
      <Layout title="Blog | Be My Guest Vietnamilainen Ravintola | Jätkäsaari Helsinki">
        <NavbarContainer dark />
        <BlogHeader title={title} />
        <BlogPost
          time={time}
          article={article}
          photos={photos}
          postId={postId}
        />
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default BlogPage
